<template>
  <div>
    <template v-for="(item, index) in options">
      <template
        v-if="
          values.includes(item.dataType === 1 ? String(item.value) : item.value)
        "
      >
        <span
          v-if="item.elTagType == 'default' || item.elTagType == ''"
          :key="item.value"
          :index="index"
          :class="item.elTagClass"
          >{{ item.label + ' ' }}</span
        >
        <a-tag
          v-else
          :key="item.value + ''"
          :disable-transitions="true"
          :index="index"
          :color="item.elTagType"
          :class="item.elTagClass"
          >{{ item.label + ' ' }}</a-tag
        >
      </template>
    </template>
  </div>
</template>

<script setup>
  // // 记录未匹配的项
  import { computed } from 'vue';

  const props = defineProps({
    // 数据
    options: {
      type: Array,
      default: null,
    },
    // 当前的值
    value: [Number, String, Array],
    // 当未找到匹配的数据时，显示value
    showValue: {
      type: Boolean,
      default: true,
    },
  });

  const values = computed(() => {
    if (props.value !== null && typeof props.value !== 'undefined') {
      return Array.isArray(props.value) ? props.value : [props.value];
    }
    return [];
  });
</script>

<style scoped>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
</style>
