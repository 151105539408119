export default {
  'login.form.title': 'HR平台',
  'login.form.userName.errMsg': '用户名不能为空',
  'login.form.password.errMsg': '密码不能为空',
  'login.form.code.errMsg': '验证码不能为空',
  'login.form.login.errMsg': '登录出错，轻刷新重试',
  'login.form.login.success': '欢迎使用',
  'login.form.userName.placeholder': '请输入用户名',
  'login.form.password.placeholder': '请输入密码',
  'login.form.code.placeholder': '验证码',
  'login.form.rememberPassword': '记住密码',
  'login.form.forgetPassword': '忘记密码',
  'login.form.login': '登录',
  'login.form.register': '注册账号',
};
