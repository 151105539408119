/* eslint-disable no-restricted-syntax */
const TOKEN_KEY = 'token';

const isLogin = () => {
  return !!localStorage.getItem(TOKEN_KEY);
};

const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

const tansParams = (params: any) => {
  let result = '';
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    const part = `${encodeURIComponent(propName)}=`;
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (
            value[key] !== null &&
            value[key] !== '' &&
            typeof value[key] !== 'undefined'
          ) {
            // eslint-disable-next-line no-shadow
            const params = `${propName}[${key}]`;
            const subPart = `${encodeURIComponent(params)}=`;
            result += `${subPart + encodeURIComponent(value[key])}&`;
          }
        }
      } else {
        result += `${part + encodeURIComponent(value)}&`;
      }
    }
  }
  return result;
};

const blobValidate = async (data: any) => {
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
};

const handleTree = (
  data: any,
  id: string,
  parentId?: string,
  children?: any
) => {
  const config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children',
  };

  const childrenListMap: any = {};
  const nodeIds: any = {};
  const tree: any = [];

  for (const d of data) {
    // eslint-disable-next-line no-shadow
    const parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }

  for (const d of data) {
    // eslint-disable-next-line no-shadow
    const parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }

  for (const t of tree) {
    // eslint-disable-next-line no-use-before-define
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o: any) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (const c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  return tree;
};

const mergeRecursive = (source: any, target: any) => {
  // eslint-disable-next-line guard-for-in
  for (const p in target) {
    try {
      if (target[p].constructor === Object) {
        source[p] = mergeRecursive(source[p], target[p]);
      } else {
        source[p] = target[p];
      }
    } catch (e) {
      source[p] = target[p];
    }
  }
  return source;
};

const changeNameFunc = (name: string) => {
  const arr = name.split('');
  // eslint-disable-next-line array-callback-return
  arr.map((item: string, index: number) => {
    if (item === '_') {
      arr.splice(index, 1);
      arr[index] = arr[index].toUpperCase();
    }
  });
  return arr.join('');
};

const selectDictLabel = (datas: any, value: any) => {
  if (value === undefined) {
    return '';
  }
  const actions = [];
  // eslint-disable-next-line consistent-return, array-callback-return
  Object.keys(datas.value).some((key: any) => {
    if (datas.value[key].value === `${value}`) {
      actions.push(datas.value[key].label);
      return true;
    }
  });
  if (actions.length === 0) {
    actions.push(value);
  }
  return actions.join('');
};

export {
  isLogin,
  getToken,
  setToken,
  clearToken,
  tansParams,
  blobValidate,
  handleTree,
  mergeRecursive,
  changeNameFunc,
  selectDictLabel,
};
