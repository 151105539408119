import { ref, toRefs } from 'vue';
import { useDictStore } from '@/store';
import { getDicts } from '@/api/dictData';
import { changeNameFunc } from '@/utils/auth';

const useDict = (args: any[]) => {
  const res = ref<any>({});
  return (() => {
    args.forEach((dictType) => {
      const dictname = changeNameFunc(dictType);
      res.value[dictname] = [];
      const dicts = useDictStore().setDict(dictType);
      if (dicts !== undefined) {
        res.value[dictType] = dicts;
      } else {
        getDicts(dictType).then((resp: any) => {
          res.value[dictname] = resp.data.map((p: any) => ({
            label: p.dictLabel,
            value: p.dataType === 1 ? String(p.dictValue) : Number(p.dictValue),
            dataType: p.dataType,
            elTagType: p.listClass,
            elTagClass: p.cssClass,
          }));
          useDictStore().setDict(dictType, res.value[dictType]);
        });
      }
    });
    return toRefs(res.value);
  })();
};

export default useDict;
