import { createApp } from 'vue';
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import globalComponents from '@/components';
import svgIcon from '@/components/SvgIcon/index.vue';
import DictTag from '@/components/DictTag/index.vue';
import useDict from '@/utils/dict';
import router from './router';
import store from './store';
import i18n from './locale';
import directive from './directive';
import App from './App.vue';

import '@/assets/style/global.less';
// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register';

const app = createApp(App);

app.config.globalProperties.useDict = useDict;
app.use(ArcoVue, {});
app.use(ArcoVueIcon);
app.use(store);
app.use(router);
app.use(i18n);
app.use(globalComponents);
app.use(directive);
app.component('SvgIcon', svgIcon);
app.component('DictTag', DictTag);
app.mount('#app');
