// eslint-disable-next-line import/no-cycle
import SaaS from '@/utils/request';

export interface LoginData {
  username: string;
  password: string;
}

export interface LoginRes {
  token: string;
}
export function login(data: LoginData) {
  return SaaS.post('/login', data);
}

export function useradminLogin(data: LoginData) {
  return SaaS.post('/adminLogin', data);
}

export function logout() {
  return SaaS.post('/logout');
}

export function getUserInfo() {
  return SaaS.get('/getInfo');
}

export function getMenuList() {
  return SaaS.get('/getRouters');
}

export function getCodeImg() {
  return SaaS.get('/captchaImage');
}

export function treeList() {
  return SaaS.get('/system/user/deptTree');
}

export function getAuthRole(userId?: any) {
  return SaaS.get(`/system/user/authRole/${userId}`);
}

export function updateAuthRole(userId?: any, roleIds?: any) {
  return SaaS.put(`/system/user/authRole?userId=${userId}&roleIds=${roleIds}`);
}
