/* eslint-disable prettier/prettier */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Modal, Message } from '@arco-design/web-vue';
// eslint-disable-next-line import/no-cycle
import { saveAs } from 'file-saver';
// eslint-disable-next-line import/no-cycle
import useUser from '@/hooks/user';
import { getToken, tansParams, blobValidate } from '@/utils/auth';

const errorCode: any = {
  '401': '认证失败，无法访问系统资源',
  '403': '当前操作没有权限',
  '404': '访问资源不存在',
  'default': '系统未知错误，请反馈给管理员',
};

const isRelogin: any = { show: false };

export type requestObj = {
  url: any;
  data: string;
  time: any;
};

class Request {
  private instance: AxiosInstance | undefined;

  constructor(requeseConfig: AxiosRequestConfig) {
    this.instance = axios.create(requeseConfig);

    // 全局请求拦截
    this.instance.interceptors.request.use(
      (config:any) => {
        const token = String(getToken());
        config.headers.ApplicationId = `1712305342180593665`
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        if (localStorage.getItem('tenantId')) {
          config.headers.Tenantid = `${localStorage.getItem('tenantId')}`
        }
        if (config.method === 'get' && config.params) {
          let url = `${config.url}?${tansParams(config.params)}`;
          url = url.slice(0, -1);
          config.params = {};
          config.url = url;
        }
        if (config.method === 'post' || config.method === 'put') {
          // eslint-disable-next-line no-shadow
          const requestObj: requestObj = {
            url: config.url,
            data:
              typeof config.data === 'object'
                ? JSON.stringify(config.data)
                : config.data,
            time: new Date().getTime(),
          };
          const sessionObj = sessionStorage.getItem('sessionObj');
          if (
            sessionObj === undefined ||
            sessionObj === null ||
            sessionObj === ''
          ) {
            sessionStorage.setItem('sessionObj', JSON.stringify(requestObj));
          } else {
            const { url, data, time } = JSON.parse(sessionObj);
            const interval = 1000; // 间隔时间(ms)，小于此时间视为重复提交
            if (
              data === requestObj.data &&
              requestObj.time - time < interval &&
              url === requestObj.url
            ) {
              const message = '数据正在处理，请勿重复提交';
              return Promise.reject(new Error(message));
            }
            sessionStorage.setItem('sessionObj', JSON.stringify(requestObj));
          }
        }
        return config;
      },
      (error) => {
        return error;
      }
    );

    // 全局响应拦截
    this.instance.interceptors.response.use(
      (res) => {
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode.default;
        // 二进制数据则直接返回
        if (
          res.request.responseType === 'blob' ||
          res.request.responseType === 'arraybuffer'
        ) {
          return res.data;
        }
        if (code === 401) {
          if (!isRelogin.show) {
            isRelogin.show = true;
            Modal.info({
              title: '系统提示',
              content: '登录状态已过期，您可以继续留在该页面，或者重新登录',
              okText: '重新登录',
              cancelText: '取消',
              maskClosable: false,
              onOk: () => {
                isRelogin.show = false;
                const { logout } = useUser();
                logout();
              },
              onCancel: () => {
                isRelogin.show = false;
              },
            });
          }
          // eslint-disable-next-line prefer-promise-reject-errors
          return '无效的会话，或者会话已过期，请重新登录。';
        }
        if (code === 500) {
          Message.error({
            content: msg,
          });
          return new Error(msg);
        }
        if (code !== 200) {
          Message.error({
            content: msg,
          });
          return msg;
        }
        return res.data;
      },
      (error) => {
        let { message } = error;
        if (message === 'Network Error') {
          message = '后端接口连接异常';
        } else if (message.includes('timeout')) {
          message = '系统接口请求超时';
        } else if (message.includes('Request failed with status code')) {
          message = `系统接口${message.substr(message.length - 3)}异常`;
        }
        Message.error({
          content: message,
          duration: 3000,
        });
        return error;
      }
    );
  }

  get(url: string, data = {}) {
    return new Promise((resolve, reject) => {
      this.instance
        ?.get(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }

  post(url: string, data = {}) {
    return new Promise((resolve, reject) => {
      this.instance
        ?.post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }

  put(url: string, data = {}) {
    return new Promise((resolve, reject) => {
      this.instance
        ?.put(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }

  delete(url: string) {
    return new Promise((resolve, reject) => {
      this.instance
        ?.delete(url)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }

  down(url: string, params?: object, filename?: string) {
    return this.instance
      ?.post(url, params, {
        transformRequest: [
          () => {
            return tansParams(params);
          },
        ],
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
      })
      .then(async (data: any) => {
        const isLogin = await blobValidate(data);
        if (isLogin) {
          const blob = new Blob([data]);
          saveAs(blob, filename);
        } else {
          const resText = await data.text();
          const rspObj = JSON.parse(resText);
          const errMsg = errorCode[rspObj.code] || rspObj.msg;
          Message.error({ content: errMsg });
        }
      })
      .catch(() => {
        Message.error({ content: '下载文件出现错误，请联系管理员！' });
      });
  }

  request<T>(config: AxiosRequestConfig<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      /* eslint-disable */
      this.instance
        ?.request<any, T>(config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

const SaaS: Request = new Request({
  baseURL: '/api',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

export default SaaS;
