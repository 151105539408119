import localeLogin from '@/views/login/locale/zh-CN';

import localeSuccess from '@/views/result/success/locale/zh-CN';
import localeError from '@/views/result/error/locale/zh-CN';

import localeRole from '@/views/system/role/locale/zh-CN';
import localeUser from '@/views/system/user/locale/zh-CN';
import localeDept from '@/views/system/dept/locale/zh-CN';
import localeMenu from '@/views/system/menu/locale/zh-CN';
import localePost from '@/views/system/post/locale/zh-CN';
import localeDict from '@/views/system/dict/locale/zh-CN';
import localeonline from '@/views/system/online/locale/zh-CN';
import localelog from '@/views/system/log/locale/zh-CN';

import localeSettings from './zh-CN/settings';

export default {
  'menu.system': '系统管理',
  'menu.home': '首页',
  'menu.server.workplace': '工作台-服务端',
  'menu.server.monitor': '实时监控-服务端',
  'menu.list': '列表页',
  'menu.result': '结果页',
  'menu.exception': '异常页',
  'menu.form': '表单页',
  'menu.profile': '详情页',
  'menu.visualization': '数据可视化',
  'menu.user': '个人中心',
  'menu.arcoWebsite': 'HR 系统',
  'menu.faq': '常见问题',
  'navbar.docs': '文档中心',
  'navbar.action.locale': '切换为中文',
  ...localeSettings,
  ...localeLogin,
  ...localeUser,

  ...localeDict,
  ...localeMenu,
  ...localelog,
  ...localePost,
  ...localeDept,
  ...localeRole,
  ...localeSuccess,
  ...localeError,

  ...localeonline,
};
