import localeLogin from '@/views/login/locale/en-US';

import localeSuccess from '@/views/result/success/locale/en-US';
import localeError from '@/views/result/error/locale/en-US';

import localeRole from '@/views/system/role/locale/en-US';
import localeUser from '@/views/system/user/locale/en-US';
import localeMenu from '@/views/system/menu/locale/en-US';
import localeDept from '@/views/system/dept/locale/en-US';
import localePost from '@/views/system/post/locale/en-US';
import localeDict from '@/views/system/dict/locale/en-US';
import localeonline from '@/views/system/online/locale/en-US';
import localelog from '@/views/system/log/locale/en-US';

import localeSettings from './en-US/settings';

export default {
  'menu.system': 'System',
  'menu.home': 'home',
  'menu.server.system': 'System-Server',
  'menu.server.workplace': 'Workplace-Server',
  'menu.server.monitor': 'Monitor-Server',
  'menu.list': 'List',
  'menu.result': 'Result',
  'menu.exception': 'Exception',
  'menu.form': 'Form',
  'menu.profile': 'Profile',
  'menu.visualization': 'Data Visualization',
  'menu.user': 'User Center',
  'menu.arcoWebsite': 'HR',
  'menu.faq': 'FAQ',
  'navbar.docs': 'Docs',
  'navbar.action.locale': 'Switch to English',
  ...localeSettings,
  ...localeLogin,
  ...localeUser,

  ...localeMenu,
  ...localelog,
  ...localePost,
  ...localeDept,
  ...localeRole,

  ...localeDict,
  ...localeSuccess,
  ...localeError,

  ...localeonline,
};
