// eslint-disable-next-line import/no-cycle
import SaaS from '@/utils/request';

export function listMenu(data?: any) {
  return SaaS.get('/admin/menu/list', { params: data });
}

export function getAdminRouters() {
  return SaaS.get('/getAdminRouters');
}

export function treeselect() {
  return SaaS.get('/system/menu/treeselect');
}

export function roleMenuTreeselect(roleId: string) {
  return SaaS.get(`/system/menu/roleMenuTreeselect/${roleId}`);
}

export function getMenu(adminMenuId?: string) {
  return SaaS.get(`/admin/menu/${adminMenuId}`);
}

export function addMenu(data?: any) {
  return SaaS.post('/admin/menu', data);
}

export function updateMenu(data?: any) {
  return SaaS.put('/admin/menu', data);
}

export function delMenu(adminMenuId?: string) {
  return SaaS.delete(`/admin/menu/${adminMenuId}`);
}
