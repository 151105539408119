import { DirectiveBinding } from 'vue';
import { useUserStore } from '@/store';

function checkPermission(el: HTMLElement, binding: DirectiveBinding) {
  const { value } = binding;
  const userStore = useUserStore();

  if (Array.isArray(value)) {
    if (value.length > 0) {
      const permissionValues = value;
      const { permissions } = userStore;
      const allPermission = '*:*:*';

      const hasPermission = permissions.some((permission) => {
        return (
          allPermission === permission || permissionValues.includes(permission)
        );
      });
      if (!hasPermission && el.parentNode) {
        el.parentNode.removeChild(el);
      }
    }
  } else {
    throw new Error(`请设置操作权限标签值`);
  }
}

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding);
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    checkPermission(el, binding);
  },
};
