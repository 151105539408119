import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-cycle
import {
  login as userLogin,
  logout as userLogout,
  useradminLogin,
  getUserInfo,
  LoginData,
} from '@/api/user';
import { setToken, clearToken } from '@/utils/auth';
import { removeRouteListener } from '@/utils/route-listener';
import { UserState } from './types';
import useAppStore from '../app';

const useUserStore = defineStore('user', {
  state: (): UserState => ({
    name: undefined,
    avatar: undefined,
    job: undefined,
    organization: undefined,
    location: undefined,
    email: undefined,
    introduction: undefined,
    personalWebsite: undefined,
    jobName: undefined,
    organizationName: undefined,
    locationName: undefined,
    phone: undefined,
    registrationDate: undefined,
    accountId: undefined,
    certification: undefined,
    role: [],
    permissions: [],
    user: {},
  }),

  getters: {
    userInfo(state: UserState): UserState {
      return { ...state };
    },
  },

  actions: {
    // Set user's information
    setInfo(partial: Partial<UserState>) {
      this.$patch(partial);
    },

    // Reset user's information
    resetInfo() {
      this.$reset();
    },

    // Get user's information
    async info() {
      const res: any = await getUserInfo();
      const { user, permissions, role } = res;
      this.setInfo(user);
      this.avatar =
        user.avatar === '' || user.avatar == null
          ? 'https://hr.anyin.link/assets/logo.e87588c9.svg'
          : user.avatar;
      this.permissions = permissions;
      this.role = role;
      this.user = user;
    },

    // Login
    async login(loginForm: LoginData) {
      try {
        localStorage.removeItem('isadmin');
        localStorage.removeItem('tenantId');
        const res: any = await userLogin(loginForm);
        setToken(res.token);
        localStorage.setItem('tenantId', res.tenantId);
      } catch (err) {
        clearToken();
        throw err;
      }
    },

    // Login
    async adminlogin(loginForm: LoginData) {
      try {
        localStorage.removeItem('tenantId');
        const res: any = await useradminLogin(loginForm);
        setToken(res.token);
        localStorage.setItem('isadmin', 'true');
      } catch (err) {
        clearToken();
        throw err;
      }
    },

    logoutCallBack() {
      const appStore = useAppStore();
      this.resetInfo();
      clearToken();
      removeRouteListener();
      appStore.clearServerMenu();
    },
    // Logout
    async logout() {
      try {
        await userLogout();
        localStorage.removeItem('isadmin');
        localStorage.removeItem('tenantId');
      } finally {
        this.logoutCallBack();
      }
    },
  },
});

export default useUserStore;
