// eslint-disable-next-line import/no-cycle
import SaaS from '@/utils/request';

// eslint-disable-next-line import/prefer-default-export

export function listMenu(data?: any) {
  return SaaS.get('/system/menu/list', { params: data });
}

export function getRouters() {
  return SaaS.get('/getRouters');
}

export function treeselect() {
  return SaaS.get('/system/menu/treeselect');
}

export function roleMenuTreeselect(roleId: string) {
  return SaaS.get(`/system/menu/roleMenuTreeselect/${roleId}`);
}

export function getMenu(menuId?: string) {
  return SaaS.get(`/system/menu/${menuId}`);
}

export function addMenu(data?: any) {
  return SaaS.post('/system/menu', data);
}

export function updateMenu(data?: any) {
  return SaaS.put('/system/menu', data);
}

export function delMenu(menuId?: string) {
  return SaaS.delete(`/system/menu/${menuId}`);
}
