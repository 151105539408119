import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-cycle

import { DictState } from './types';

const useDictStore = defineStore('dict', {
  state: (): DictState => ({
    dict: [],
  }),

  actions: {
    // eslint-disable-next-line consistent-return
    getDict(key?: any) {
      if (key == null && key === '') {
        return null;
      }
      try {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.dict.length; i++) {
          if (this.dict[i].key === key) {
            return this.dict[i].value;
          }
        }
      } catch (e) {
        return null;
      }
    },

    setDict(key?: any, value?: any) {
      if (key !== null && key !== '') {
        this.dict.push({
          key,
          value,
        });
      }
    },

    // eslint-disable-next-line consistent-return
    removeDict(key?: any) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.dict.length; i++) {
        if (this.dict[i].key === key) {
          this.dict.splice(i, i);
          return true;
        }
      }
    },

    cleanDict() {
      this.dict = [];
    },
  },
});

export default useDictStore;
