export const WHITE_LIST = [
  { name: 'notFound', children: [] },
  { name: 'login', children: [] },
  { name: 'authorizelogin', children: [] },
  { name: 'loginout', children: [] },
];

export const NOT_FOUND = {
  name: 'notFound',
};

export const REDIRECT_ROUTE_NAME = 'Redirect';

export const DEFAULT_ROUTE_NAME = 'User';

export const DEFAULT_ROUTE = {
  title: 'menu.system.user',
  name: DEFAULT_ROUTE_NAME,
  fullPath: '/system/user',
};
