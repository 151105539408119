import { defineStore } from 'pinia';
import { Notification } from '@arco-design/web-vue';
import type { NotificationReturn } from '@arco-design/web-vue/es/notification/interface';
import type { RouteRecordNormalized } from 'vue-router';
import defaultSettings from '@/config/settings.json';
// eslint-disable-next-line import/no-cycle
import { getRouters } from '@/api/menu';
import { getAdminRouters } from '@/api/adminmenu';
import { AppState } from './types';

export const Layout = () => import('@/layout/default-layout.vue');
const modules = import.meta.glob('@/views/**/**.vue');

const filterAsyncRouter = (asyncRouterMap: any, type = false) => {
  return asyncRouterMap.filter((route: any) => {
    if (route.component === 'Layout') {
      route.component = Layout;
    } else if (route.component === 'ParentView') {
      route.component = false;
    } else {
      route.component = modules[`/src/views/${route.component}.vue`] as any;
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children, type);
    } else {
      delete route.children;
      delete route.redirect;
    }
    return true;
  });
};

const useAppStore = defineStore('app', {
  state: (): AppState => ({ ...defaultSettings }),

  getters: {
    appCurrentSetting(state: AppState): AppState {
      return { ...state };
    },
    appDevice(state: AppState) {
      return state.device;
    },
    appAsyncMenus(state: AppState): RouteRecordNormalized[] {
      return state.serverMenu as unknown as RouteRecordNormalized[];
    },
  },

  actions: {
    // Update app settings
    updateSettings(partial: Partial<AppState>) {
      // @ts-ignore-next-line
      this.$patch(partial);
    },

    // Change theme color
    toggleTheme(dark: boolean) {
      if (dark) {
        this.theme = 'dark';
        document.body.setAttribute('arco-theme', 'dark');
      } else {
        this.theme = 'light';
        document.body.removeAttribute('arco-theme');
      }
    },
    toggleDevice(device: string) {
      this.device = device;
    },
    toggleMenu(value: boolean) {
      this.hideMenu = value;
    },
    async fetchServerMenuConfig() {
      let notifyInstance: NotificationReturn | null = null;
      try {
        let res: any = null;
        if (localStorage.getItem('isadmin') === 'true') {
          res = await getAdminRouters();
        } else {
          res = await getRouters();
        }
        const mod: any = await filterAsyncRouter(res.data);
        this.serverMenu = mod;
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        notifyInstance = Notification.error({
          id: 'menuNotice',
          content: 'error',
          closable: true,
        });
      }
    },
    clearServerMenu() {
      this.serverMenu = [];
    },
  },
});

export default useAppStore;
