import SaaS from '@/utils/request';

// eslint-disable-next-line import/prefer-default-export

export function listData(data?: any) {
  return SaaS.get('/system/dict/data/list', { params: data });
}

export function getData(dictCode?: string) {
  return SaaS.get(`/system/dict/data/${dictCode}`);
}

export function getDicts(dictType?: string) {
  return SaaS.get(`/system/dict/data/type/${dictType}`);
}

export function addData(data?: any) {
  return SaaS.post('/system/dict/data', data);
}

export function updateData(data?: any) {
  return SaaS.put('/system/dict/data', data);
}

export function delData(dictCode?: string) {
  return SaaS.delete(`/system/dict/data/${dictCode}`);
}
