import { useRouter } from 'vue-router';
import { Message } from '@arco-design/web-vue';

// eslint-disable-next-line import/no-cycle
import { useUserStore } from '@/store';

export default function useUser() {
  const router = useRouter();
  const userStore = useUserStore();
  const logout = async () => {
    await userStore.logout();
    if (router === undefined) {
      window.location.reload();
    }
    Message.success('登出成功');
    router.push({
      name: 'loginout',
    });
  };
  return {
    logout,
  };
}
