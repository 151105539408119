import type { Router, RouteRecordNormalized } from 'vue-router';
import NProgress from 'nprogress'; // progress bar

import { useAppStore } from '@/store';
import { WHITE_LIST, NOT_FOUND } from '../constants';

export default function setupPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const appStore = useAppStore();

    const addRouters = (routers: any[], name?: string) => {
      routers.forEach((item: any) => {
        if (name) {
          router.addRoute(name, item);
        } else {
          router.addRoute(item);
        }
        if (item.children && item.children.length > 0) {
          addRouters(item.children, item.name);
        }
      });
    };

    if (appStore.menuFromServer) {
      if (
        !appStore.appAsyncMenus.length &&
        !WHITE_LIST.find((el) => el.name === to.name)
      ) {
        await appStore.fetchServerMenuConfig();
        addRouters(appStore.appAsyncMenus);
        next({ ...to, replace: true });
      }
      const serverMenuConfig = [...appStore.appAsyncMenus, ...WHITE_LIST];

      let exist = false;
      while (serverMenuConfig.length && !exist) {
        const element = serverMenuConfig.shift();

        if (element?.name === to.name) exist = true;

        if (element?.children) {
          serverMenuConfig.push(
            ...(element.children as unknown as RouteRecordNormalized[])
          );
        }
      }
      if (exist) {
        next();
      } else next(NOT_FOUND);
    } else {
      next(NOT_FOUND);
    }
    NProgress.done();
  });
}
